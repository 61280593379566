import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import './assets/scss/style.scss'
import Spinner from './components/common/Spin'
const Home = React.lazy(() => import('./features/home/home'))

// Pages
const Login = React.lazy(() => import('./pages/login/Login'))
const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

const App = ({ pca }) => {
  return (
    <MsalProvider instance={pca}>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route exact path="/" name="Login" element={<Login />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<Home />} />
        </Routes>
      </Suspense>
    </MsalProvider>
  )
}

export default App
